import { storeToRefs } from 'pinia'

import { useSegmentIdentify } from '~/composables/Segment/useSegmentIdentify'
import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'

import { useDraftStore } from '~/stores/draft'
import { useDraftPricingStore } from '~/stores/draftPricing'
import { useDraftTrackStore } from '~/stores/draftTrack'
import { useDraftTrackTagsStore } from '~/stores/draftTrackTags'
import { useMiscSendtrackFiltersStore } from '~/stores/miscSendtrackFilters'
import { useTagStore } from '~/stores/tag'
import { useUserBandStore } from '~/stores/userBand'

import { TagTypeKeys } from '~/enums/TagTypeKeys'

import type { Band } from '~/types/band'
import type {
  CampaignBaseProperties,
  CampaignInitiatedFrom,
  PageClickedFrom,
} from '~/types/Segment/SegmentEventLibrary/SegmentEventLibraryCampaign'
import type TagTypeKey from '~/types/tagTypeKey'

export function useSegmentTrackCampaign() {
  const { $pinia } = useNuxtApp()
  const route = useRoute()
  const segmentTrack = useSegmentTrack()
  const segmentIdentify = useSegmentIdentify()

  const {
    id: DRAFT_TRACK_ID,
    release_date: DRAFT_TRACK_RELEASE_DATE,
    link: DRAFT_TRACK_LINK,
    ep_link: DRAFT_TRACK_EP_LINK,
    secondary_link: DRAFT_TRACK_SECONDARY_LINK,
    is_demo: DRAFT_TRACK_IS_DEMO,
    name: DRAFT_TRACK_NAME,
  } = storeToRefs(useDraftTrackStore($pinia))
  const { identity: DRAFT_IDENTITY_TAGS } = storeToRefs(
    useDraftTrackTagsStore($pinia),
  )
  const {
    id: DRAFT_ID,
    influencers: DRAFT_INFLUENCERS,
    tags: DRAFT_TAG_IDS,
    goals: DRAFT_GOALS,
    messages: DRAFT_MESSAGES,
    info: DRAFT_INFO,
  } = storeToRefs(useDraftStore($pinia))
  const { selected_tag_ids: SELECTED_FILTERS } = storeToRefs(
    useMiscSendtrackFiltersStore($pinia),
  )
  const { BAND_DATA } = storeToRefs(useUserBandStore($pinia))
  const { MISSING: MISSING_GROOVIZ, NEED_PURCHASE: NEED_GROOVIZ } = storeToRefs(
    useDraftPricingStore($pinia),
  )

  const { GET_TAGS_FROM_IDS, GET_TAGS_FROM_TYPE_NAME } = useTagStore($pinia)

  function getBaseDraftData(): CampaignBaseProperties {
    return {
      band_name: (BAND_DATA.value as Band).name,
      id_band: (BAND_DATA.value as Band).id,
      id_draft: DRAFT_ID.value,
    }
  }
  function getSelectedTagNamesForTagType(tagTypeName: TagTypeKey): string[] {
    return GET_TAGS_FROM_TYPE_NAME(tagTypeName)
      .filter((tag) => SELECTED_FILTERS.value.includes(tag.id))
      .map((tag) => tag.name)
  }

  function trackCampaignAccordionChanged(
    isContentVisible: boolean,
    accordion: 'selected_curators' | 'suggested_for_you' | 'shared_your_music',
  ): void {
    segmentTrack('Campaign - Accordion State Changed', {
      state_changed_to: isContentVisible ? 'opened' : 'closed',
      accordion,
      num_curators_selected: DRAFT_INFLUENCERS.value.length,
      page_title: route.name?.toString() ?? null,
    })
  }

  function trackCampaignAddMoreCurators(
    pageClickedFrom = 'draft-recap' as PageClickedFrom,
  ): void {
    segmentTrack('Campaign - Add More Curators Clicked', {
      id_draft: DRAFT_ID.value,
      page_clicked_from: pageClickedFrom,
    })
  }

  function trackCampaignInitiated(
    campaignInitiatedFrom: CampaignInitiatedFrom,
  ): void {
    const { id_band: idBand, band_name: bandName } = getBaseDraftData()
    segmentTrack('Campaign - New Campaign Initiated', {
      id_band: idBand,
      band_name: bandName,
      page_title: route.name?.toString() ?? null,
      new_campaign_initiated_from: campaignInitiatedFrom,
    })
  }

  function trackCustomMessagesDisplayToggled(isDisplayed: boolean): void {
    segmentIdentify({
      user_id: (BAND_DATA.value as Band).id,
    })
    segmentTrack('Messages - Personalized Messages Toggled', {
      id_draft: DRAFT_ID.value,
      toggle_status: isDisplayed ? 'toggled_on' : 'toggled_off',
      num_curators_selected: DRAFT_INFLUENCERS.value.length,
    })
  }

  function trackTrackStepFinalized(step: number): void {
    if (step === 1) {
      const trackLangTags = GET_TAGS_FROM_IDS(
        DRAFT_IDENTITY_TAGS.value.lyrics_lang ?? [],
      )
      const trackLangs = trackLangTags.map((lang) => lang.name)

      segmentTrack('Campaign - Step 1 - Track Step Finalized', {
        band_name: (BAND_DATA.value as Band).name,
        release_date: DRAFT_TRACK_RELEASE_DATE.value
          ? new Date(DRAFT_TRACK_RELEASE_DATE.value)
          : undefined,
        has_shared_track_ep_link: DRAFT_TRACK_EP_LINK.value.length > 0,
        has_shared_track_spotify_link:
          DRAFT_TRACK_SECONDARY_LINK.value.length > 0,
        has_shared_primary_link: DRAFT_TRACK_LINK.value.length > 0,
        id_band: (BAND_DATA.value as Band).id,
        id_draft: DRAFT_ID.value,
        is_demo: DRAFT_TRACK_IS_DEMO.value,
        primary_link: DRAFT_TRACK_LINK.value,
        track_languages_list: trackLangs || null,
        track_title: DRAFT_TRACK_NAME.value,
      })
    } else if (step === 4) {
      segmentIdentify({
        id_band: (BAND_DATA.value as Band).id,
      })
      segmentTrack('Campaign - Step 4 - Track Links Finalized', {
        primary_link: DRAFT_TRACK_LINK.value,
        has_shared_track_spotify_link:
          DRAFT_TRACK_SECONDARY_LINK.value.length > 0,
        has_shared_track_ep_link: DRAFT_TRACK_EP_LINK.value.length > 0,
        has_shared_primary_link: DRAFT_TRACK_LINK.value.length > 0,
        id_draft: DRAFT_ID.value,
        id_track: DRAFT_TRACK_ID.value,
      })
    }
  }

  function trackGoalsStepFinalized(): void {
    const filterTagNames = GET_TAGS_FROM_IDS(DRAFT_TAG_IDS.value).map(
      (tag) => tag.name,
    )

    segmentTrack('Campaign - Step 2 - Goals Step Finalized', {
      ...getBaseDraftData(),
      budget_range: undefined,
      campaign_goals_list: DRAFT_GOALS.value,
      has_specified_budget: false,
      preselection_filter_subcategory_list:
        filterTagNames && filterTagNames.length ? filterTagNames : undefined,
    })
  }

  function trackCuratorsSelectionValidated(): void {
    const tags = GET_TAGS_FROM_IDS(SELECTED_FILTERS.value)
    const tagTypesToGet = Object.keys(TagTypeKeys) as TagTypeKey[]
    const selectedTagNamesByType = tagTypesToGet.reduce(
      (acc, tagType) => {
        acc[tagType] = getSelectedTagNamesForTagType(tagType)
        return acc
      },
      {} as Record<TagTypeKey, string[]>,
    )

    segmentTrack('Campaign - Step 2 - Curators Selection Validated', {
      ...getBaseDraftData(),
      badges_filtered_to: selectedTagNamesByType.influencer_badge,
      countries_filtered_to: selectedTagNamesByType.country,
      curator_types_filtered_to: selectedTagNamesByType.influencer_kind,
      has_filtered_to_new_curators:
        selectedTagNamesByType.influencer_mark.includes('new'),
      id_track: DRAFT_TRACK_ID.value,
      interactions_filtered_to: selectedTagNamesByType.interaction,
      music_genres_filtered_to: selectedTagNamesByType.subgenre,
      num_curators_selected: DRAFT_INFLUENCERS.value.length,
      postselection_filter_subcategory_list: [...tags.map((tag) => tag.name)],
    })
  }

  function trackCuratorsSelectionFinalized(): void {
    segmentTrack('Campaign - Step 2 - Selection Step Finalized', {
      ...getBaseDraftData(),
      num_curators_selected: DRAFT_INFLUENCERS.value.length,
    })
  }

  function trackMessageStepFinalized(): void {
    segmentTrack('Campaign - Step 3 - Messages Step Finalized', {
      ...getBaseDraftData(),
      has_enough_grooviz_for_the_campaign: !NEED_GROOVIZ.value,
      has_written_personal_message:
        Object.keys(DRAFT_MESSAGES.value).length > 0,
      has_written_pitch: (DRAFT_INFO.value?.length ?? 0) > 0,
    })
  }

  function trackCampaignSummaryFinalized(
    buttonAction: 'send campaign' | 'go to payment',
  ): void {
    segmentTrack('Campaign - Step 4 - Campaign Summary Finalized', {
      ...getBaseDraftData(),
      button_action: buttonAction,
      num_missing_grooviz: MISSING_GROOVIZ.value,
    })
  }

  return {
    trackCampaignAccordionChanged,
    trackCampaignAddMoreCurators,
    trackCampaignInitiated,
    trackCustomMessagesDisplayToggled,
    trackTrackStepFinalized,
    trackGoalsStepFinalized,
    trackCuratorsSelectionValidated,
    trackCuratorsSelectionFinalized,
    trackMessageStepFinalized,
    trackCampaignSummaryFinalized,
  } as const
}
